const style = {
  content: {
    textAlign: 'center',
  },
  height20: {
    minHeight: '20px',
  },
  height50: {
    minHeight: '50px',
  },
};

export default style;
