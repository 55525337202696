import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Layout from 'hoc/Layout/Layout.js';
import Homepage from 'components/Homepage/Homepage.js';

import asyncComponent from 'hoc/asyncComponent.js';

const asyncTemperatureConverter = asyncComponent(() => {
  return import('containers/TemperatureConverter/TemperatureConverter.js');
});
const asyncExchangeRateCalculator = asyncComponent(() => {
  return import('containers/ExchangeRateCalculator/ExchangeRateCalculator.js');
});

function App() {
  return (
    <Layout>
      <Switch>
        <Route path="/" exact component={Homepage} />
        <Route path="/exchange-rates" component={asyncExchangeRateCalculator} />
        <Route path="/temperature" component={asyncTemperatureConverter} />
      </Switch>
    </Layout>
  );
}

export default App;
