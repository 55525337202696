/*eslint-disable*/
import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// @material-ui/icons
import { WbSunny, AttachMoney } from '@material-ui/icons';

// core components
import HeaderLink from 'components/Navigation/HeaderLinks/HeaderLink/HeaderLink.js';

import styles from 'assets/jss/components/headerLinksStyle.js';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <HeaderLink link="/temperature">
          <WbSunny className={classes.icons} style={{ color: '#ffc400' }} />{' '}
          Temperature
        </HeaderLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <HeaderLink link="/exchange-rates">
          <AttachMoney className={classes.icons} /> Exchange Rates
        </HeaderLink>
      </ListItem>
    </List>
  );
}
