import React from 'react';
import { Avatar } from '@material-ui/core';

import logo from 'assets/images/logo.png';

const Logo = props => {
  return <Avatar alt={props.alt} src={logo} />;
};

export default Logo;
