import React, { Component } from 'react';

import { Container } from '@material-ui/core';

import Aux from 'hoc/Aux/Aux.js';
import Header from 'components/Navigation/Header/Header.js';
import HeaderLinks from 'components/Navigation/HeaderLinks/HeaderLinks.js';

class Layout extends Component {
  state = {
    openSideDrawer: false,
  };

  closeSideDrawerHandler = () => {
    this.setState({ openSideDrawer: false });
  };

  toggleSideDrawerHandler = () => {
    this.setState(prevState => {
      return { openSideDrawer: !prevState.openSideDrawer };
    });
  };

  render() {
    return (
      <Aux>
        <Header
          brand="Tools Kit"
          showLogo={false}
          rightLinks={<HeaderLinks />}
          fixed
          color="secondary"
          changeColorOnScroll={null}
          {...this.props}
        />
        <div style={{ paddingTop: '96px' }}>
          <Container maxWidth="md">
            <main>{this.props.children}</main>
          </Container>
        </div>

        {process.env.NODE_ENV === 'production' && (
          <script
            defer
            src="https://static.cloudflareinsights.com/beacon.min.js"
            data-cf-beacon='{"token": "e3004cd05f5646308ebcdbe07fda5ec7"}'
          ></script>
        )}
      </Aux>
    );
  }
}

export default Layout;
