import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/components/homepageStyle.js';

const useStyles = makeStyles(styles);

const HomePage = props => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.height50}></div>
      <div className={classes.content}>Welcome to Tools Kit</div>
    </React.Fragment>
  );
};

export default HomePage;
