/*eslint-disable*/
import React from 'react';
// react components for routing our app without refresh
import { NavLink, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import styles from 'assets/jss/components/headerLinks/headerLinkStyle.js';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <NavLink
      variant="body2"
      to={props.link}
      className={classes.navLink}
      color="transparent"
      activeStyle={{
        color: 'inherit',
        // backgroundColor: "rgb(25, 118, 210, 0.2)"
      }}
    >
      {props.children}
    </NavLink>
  );
}
